// letterBox
import http from '../request.js'

export default {

  // 根据主键查ID
  GetModel: (params) => {
    return http({
      url: '/api/letterbox/GetModel',
      method: 'get',
      params
    })
  },
  // 查询
  select: (data) => {
    return http({
      url: '/api/letterbox/select',
      data
    })
  },
  // gk 二维码（test
  getGk: (params) => {
    return http({
      url: '/api/letterbox/gk',
      method: 'get',
      params
    })
  },
  // 验证开箱码
  checkCode: (params) => {
    return http({
      url: '/api/letterbox/CheckCode',
      method: 'get',
      params
    })
  },
  // 销毁开箱码
  DestroyCode: (params) => {
    return http({
      url: '/api/letterbox/DestroyCode',
      method: 'get',
      params
    })
  },
  // 所有码
  getAllGk: (params) => {
    return http({
      url: '/api/letterbox/GetALlLockByphon',
      method: 'get',
      params
    })
  },
  // 开灯时间
  getLed: (data) => {
    return http({
      url: '/api/letterbox/getLed',
      data
    })
  },
  // 背景图
  getPic: (data) => {
    return http({
      url: '/api/AD/select',
      data
    })
  }
}
