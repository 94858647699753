import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import NProgress from 'nprogress'

/* 配置请求域名 */
export let baseURL = ''
// baseURL = 'http://192.168.80.103:8099/' //
// baseURL = 'http://47.107.145.178:9456/'
baseURL = 'https://huaqiao-api.ozkoalas.cn/' // 线上环境

/* 创建axios的一个实例 */
const instance = axios.create({
  baseURL,
  // baseURL: '/http', // 配置跨域
  timeout: 15000, // 请求超时毫秒数
  method: 'post'
})

/* 请求拦截器 */
instance.interceptors.request.use(
  (config) => {
    // 进度条开启
    NProgress.start()

    // 登陆后请求携带 token
    // const token = store.state.user.token
    const token = 'cqx'
    if (token) {
      config.headers.token = token
    }

    return config
  },
  (error) => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  (response) => {
    // 进度条关闭
    NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    // 多加的1 是重新认领无效客户用的
    //     !== 0
    if (res.err && res.err !== 0) {
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000,
        offset: window.innerHeight / 2
      })

      // 未登录/没有权限
      if (res.err === 401) {
        MessageBox.alert('登录已过期，请重新登录！', '提示', {
          showClose: false,
          showCancelButton: false
        }).then(() => {
          // 登出
          store.dispatch('user/Logout')
        })
      }

      return Promise.reject(res.message)
    } else {
      return response.data
    }
  },
  (error) => {
    // 进度条关闭
    NProgress.done()

    // 抛出服务器响应错误
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000,
      offset: window.innerHeight / 2
    })
    return Promise.reject(error)
  }
)

export default instance
