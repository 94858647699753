import 'babel-polyfill' // 兼容安卓

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './styles/reset.css' // 样式重置表
import './styles/global.css' // 全局样式表

import api from '@/api'

import Es6Promise from 'es6-promise' // 兼容安卓

import VScaleScreen from 'v-scale-screen'

require('es6-promise').polyfill()
Es6Promise.polyfill() // 兼容安卓
Vue.use(VScaleScreen)

Vue.prototype.$api = api.module

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
