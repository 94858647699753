<template>
  <div class="app">
    <div class="root" ref="appRef">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: 0,
      baseWidth: 1080,
      baseHeight: 1920,
      scale: {
        width: '1',
        height: '1'
      },
      baseProportion: parseFloat((1080 / 1920).toFixed(5))
    }
  },
  mounted () {
    this.windowDraw()
    this.calcRate()
  },
  methods: {
    windowDraw () {
      window.addEventListener('resize', this.resize)
    },
    resize () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.calcRate()
      }, 200)
    },
    calcRate () {
      // 当前宽高比
      const currentRate = parseFloat(
        (window.innerWidth / window.innerHeight).toFixed(5)
      )
      console.log('ref', this.$refs.appRef)
      if (this.$refs.appRef) {
        if (currentRate > this.baseProportion) {
        // 表示更宽
          this.scale.width = (
            (window.innerHeight * this.baseProportion) /
            this.baseWidth
          ).toFixed(5)
          this.scale.height = (window.innerHeight / this.baseHeight).toFixed(5)
          this.$refs.appRef.style.transform = `scale(${this.scale.width}, ${this.scale.height}) translate(-50%, -50%)`
        } else {
        // 表示更高
          this.scale.height = (
            window.innerWidth /
            this.baseProportion /
            this.baseHeight
          ).toFixed(5)
          this.scale.width = (window.innerWidth / this.baseWidth).toFixed(5)
          this.$refs.appRef.style.transform = `scale(${this.scale.width}, ${this.scale.height}) translate(-50%, -50%)`
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  background: #000;
  .root {
    width: 1080px;
    height: 1920px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    position: relative;
    // overflow: hidden;
    // background-image: url(@/assets/images/bg.png);
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
  }
}
</style>
